import React, { useSate, useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ObjectListItem from "../components/ObjectListItem"
import { AnimatePresence } from "framer-motion"
import Transition from "../components/Transition"
import { use100vh } from "react-div-100vh"
import SEO from "../components/seo"

export default function Objects({
  data: {
    allContentfulObject: { edges },
    allContentfulAbout,
    allContentfulNoPageListItem,
  },
}) {
  const height = use100vh()
  let newArr = edges.concat(allContentfulNoPageListItem.edges)
  let objectList = newArr.map((object, i) => {
    let img
    let path
    let wip

    if (object.node.gallery) {
      img = object.node.gallery[0]
    } else {
      img = false
    }

    if (object.node.path) {
      path = object.node.path
    } else {
      path = false
    }

    // if (object.node.wip) {
    //   wip = object.node.wip
    //   console.log(object.node.wip)
    // } else {
    //   wip = null
    //   // console.log(wip)
    // }

    return (
      <ObjectListItem
        itemName={object.node.objectName || object.node.name}
        year={object.node.year}
        materials={object.node.materials}
        featuredImg={img}
        path={path}
        wip={object.node.wip}
      />
    )
  })

  return (
    <Transition>
      <SEO title="Overview | Ette" />
      <Layout about={allContentfulAbout}>
        <div
          style={{ height: height, maxHeight: height }}
          className={"no-bar overflow-y-scroll flex flex-col "}
        >
          <ul className="px-5 py-3 lg:pt-36">{objectList}</ul>
        </div>
      </Layout>
    </Transition>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulObject(sort: { fields: date }) {
      edges {
        node {
          objectName
          year
          materials
          path
          gallery {
            fluid(quality: 50, maxWidth: 700) {
              base64
              srcSet
              aspectRatio
            }
            fixed(height: 700, width: 540) {
              base64
              aspectRatio
              srcSet
            }
          }
          featured
        }
      }
    }
    allContentfulNoPageListItem(sort: { fields: date }) {
      edges {
        node {
          name
          date
          materials
          year
          wip
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          aboutDescription {
            raw
          }
          pressLinks {
            linkTitle
            link
          }
          socialMediaLinks {
            linkTitle
            link
          }
        }
      }
    }
  }
`
