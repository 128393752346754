import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import ComingSoon from "../components/svg/ComingSoon"
import Wip from "../components/svg/Wip"
// import select from "../../images/select.png"
import cursor from "../images/cursorimage.png"

const ObjectListItem = ({
  itemName,
  year,
  materials,
  featuredImg,
  path,
  wip,
}) => {
  const [hovering, setHovering] = useState(false)
  const [left, setLeft] = useState(0)
  let wipClass = null

  const mouseHandlerEnter = () => {
    setHovering(true)
  }
  const mouseHandlerLeave = () => {
    setHovering(false)
  }

  const positionHandler = e => {
    setLeft(e.clientX)
  }

  useEffect(() => {
    document.addEventListener("mousemove", positionHandler)
    return () => {
      document.removeEventListener("mousemove", positionHandler)
    }
  }, [])

  const disabledLink = path ? "" : "disabled-link"

  if (wip === true) {
    wipClass = <Wip />
  } else if (wip === false) {
    wipClass = <ComingSoon />
  } else {
    wipClass = null
  }

  return (
    <>
      <li
        id={itemName}
        className={`object-list-item text-listItem w-full`}
        onMouseEnter={mouseHandlerEnter}
        onMouseLeave={mouseHandlerLeave}
      >
        <Link
          className={`flex flex-row justify-between w-full lg:grid lg:grid-cols-6 object-list-item ${disabledLink}`}
          to={"/" + path}
        >
          <h1 className={`lg:col-span-3 uppercase`}>{itemName}</h1>
          <h2 className={`lg:col-span-1 lg:pl-5 uppercase `}>{year}</h2>
          <h3 className={`hidden lg:block lg:col-span-2 uppercase `}>
            {materials}
          </h3>

          <div
            className={
              (hovering ? "opacity-100" : "opacity-0") +
              " " +
              "hidden lg:block lg:relative pointer-events-none z-300 transition-all h-1"
            }
          >
            <div
              style={{ left: left + "px" }}
              className="hover-image relative w-36"
            >
              {wipClass}
              <Img
                className=""
                fluid={featuredImg.fluid}
                imgStyle={{ objectFit: "contain" }}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </Link>
      </li>
    </>
  )
}

export default ObjectListItem
